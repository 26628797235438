<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        For ideal gases with each of the following sets of pressure (P), volume (V), moles (n), and
        temperature (T), determine the missing value.
        <span class="font-weight-bold">
          Note: these different sets of numbers are not related to one another. Pay close attention
          to the units.
        </span>
      </p>

      <v-simple-table style="max-width: 650px">
        <thead>
          <tr>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{Part}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{P}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{V}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{n (mol)}$" />
            </th>
            <th style="vertical-align: middle; text-align: center; font-size: 15px">
              <stemble-latex content="$\text{T}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="vertical-align: middle; text-align: left">
              <stemble-latex content="$\text{a)}$" />
            </td>
            <td>
              <number-value :value="Pressure1" />
              <stemble-latex content="$\text{atm}$" />
            </td>
            <td>
              <number-value :value="Volume1" />
              <stemble-latex content="$\text{L}$" />
            </td>
            <td>
              <calculation-input v-model="inputs.input1" dense class="my-2" />
            </td>
            <td>
              <number-value :value="Temperature1" />
              <stemble-latex content="$^\circ\text{C}$" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: left">
              <stemble-latex content="$\text{b)}$" />
            </td>
            <td>
              <latex-number :number="Pressure2.toFixed(1)" />
              <stemble-latex content="$\text{kPa}$" />
            </td>
            <td>
              <latex-number :number="Volume2.toFixed(1)" />
              <stemble-latex content="$\text{mL}$" />
            </td>
            <td>
              <number-value :value="Moles2" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input2"
                dense
                class="my-2"
                :append-text="'$\\text{K}$'"
              />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: left">
              <stemble-latex content="$\text{c)}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input3"
                dense
                class="my-2"
                :append-text="'$\\text{kPa}$'"
              />
            </td>
            <td>
              <number-value :value="Volume3" />
              <stemble-latex content="$\text{m}^3$" />
            </td>
            <td>
              <number-value :value="Moles3" />
            </td>
            <td>
              <number-value :value="Temperature3" />
              <stemble-latex content="$\text{K}$" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; text-align: left">
              <stemble-latex content="$\text{d)}$" />
            </td>
            <td>
              <latex-number :number="Pressure4.toFixed(1)" />
              <stemble-latex content="$\text{torr}$" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input4"
                dense
                class="my-2"
                :append-text="'$\\text{L}$'"
              />
            </td>
            <td>
              <number-value :value="Moles4" />
            </td>
            <td>
              <number-value :value="Temperature4" />
              <stemble-latex content="$\text{K}$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import LatexNumber from '@/tasks/components/displayers/LatexNumber';

export default {
  name: 'Question63',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
    };
  },
  computed: {
    Pressure1() {
      return this.taskState.getValueBySymbol('Pressure1').content;
    },
    Pressure2() {
      return this.taskState.getValueBySymbol('Pressure2').content.toFloat();
    },
    Pressure4() {
      return this.taskState.getValueBySymbol('Pressure4').content.toFloat();
    },
    Volume1() {
      return this.taskState.getValueBySymbol('Volume1').content;
    },
    Volume2() {
      return this.taskState.getValueBySymbol('Volume2').content.toFloat();
    },
    Volume3() {
      return this.taskState.getValueBySymbol('Volume3').content;
    },
    Moles2() {
      return this.taskState.getValueBySymbol('Moles2').content;
    },
    Moles3() {
      return this.taskState.getValueBySymbol('Moles3').content;
    },
    Moles4() {
      return this.taskState.getValueBySymbol('Moles4').content;
    },
    Temperature1() {
      return this.taskState.getValueBySymbol('Temperature1').content;
    },
    Temperature3() {
      return this.taskState.getValueBySymbol('Temperature3').content;
    },
    Temperature4() {
      return this.taskState.getValueBySymbol('Temperature4').content;
    },
  },
};
</script>
<style scoped>
td {
  vertical-align: middle;
  text-align: center;
}
</style>
